export default {
  data() {
    return {
      bLoading: false,
      screenWidth: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 60;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setDialogDateArrival",{active:false,sDate:""});

    },
    changeDate() {
      this.bLoading = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      const payload = {
        tArrivalAtBorderWarehouse: this.dialogDateArrivaltArrivalAtBorderWarehouse,
      };
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/arrival`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.mixSuccess(response.data.message);

          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    dialogDateArrival() {
      return this.$store.state.dialogDateArrival;
    },
    dialogDateArrivaltArrivalAtBorderWarehouse(){
      return this.$store.state.dialogDateArrivaltArrivalAtBorderWarehouse;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    dialogDateArrival() {
      if (this.dialogDateArrival) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
      }
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
      }
    },
  },
};
